<template>
  <v-row>
    <v-col>
      <v-alert color="primary" text
        ><v-icon left color="primary">mdi-check-circle-outline</v-icon>
        {{ type }} can be reconciled</v-alert
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "CustomerPaymentsReconcileAlert",
  props: ["type"],
  data() {
    return {};
  },
};
</script>